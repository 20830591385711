module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Réka Biró-Horváth Photography', // Navigation and Site Title
  siteTitleAlt: 'Réka Biró-Horváth Photography Portfolio', // Alternative Site title for SEO
  siteTitleShort: 'Réka Biró-Horváth Photography', // short_name for manifest
  siteHeadline: 'Réka Biró-Horváth Publishing & Creating stunning photos', // Headline for schema.org JSONLD
  siteUrl: 'https://photography.allwithzest.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logos/logo.png', // Used for SEO and manifest
  siteDescription: 'Food photography portfolio from Réka Biró-Horváth',
  author: 'Reka', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@allwithzest', // Twitter Username
  ogSiteName: 'allwithzest', // Facebook Site Name
  ogLanguage: 'hu_HU', // og:language
  googleAnalyticsID: 'UA-144685984-1',

  // Manifest and Progress color
  themeColor: '#16191f',
  backgroundColor: '#16191f',

  // Your information
  avatar: '/logos/profile-photo.jpg',
  name: 'Réka Biró-Horváth',
  location: 'Hungary',
  socialMedia: [
    {
      url: 'https://www.instagram.com/allwithzest',
      name: 'Instagram',
    },
    {
      url: 'https://www.allwithzest.com',
      name: 'Homepage',
    },
  ],
}
